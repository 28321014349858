<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <va-card :title="$t('mti.edit')">
        <actions
          slot="actions"
          crud-links="mti"
          :actions="actions"
          :action-data="{id: $route.params.id}"
        />
        <data-form
          :mti="mti"
          :loading="loading"
          @submit="submit"
        />
      </va-card>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'mti-edit',
  components: {
    DataForm,
    Actions,
  },
  data () {
    return {
      error: false,
      loading: false,
      actions: ['index', 'new', 'view', 'delete'],
      mti: {},
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `mti/${id}`
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const mtiId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(mtiId))
      } catch (err) {
        // console.log('Error fetching mti data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.mti = u.data.data
    },
    async submit (data) {
      this.loading = true
      const mti = data.mti
      try {
        await this.$http.put(this.routeBuilder(mti.id), mti)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      const preachingPoints = data.preachingPoints || []
      if (preachingPoints.length > 0) {
        const pointsData = {
          id: mti.id,
          preaching_points: {
            _ids: preachingPoints.slice(0),
          },
        }

        try {
          await this.$http.put(this.routeBuilder(mti.id), pointsData)
        } catch (error) {
          // console.log('Error updating data', error)
          this.loading = false
          return
        }
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
    },
  },
}
</script>
